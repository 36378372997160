.JogaJuniorMain h3 {
  text-align: center;
}

.JogaJuniorMain h4 {
  text-align: center;
}

.JogaJuniorMain p {
  text-align: justify;
}

.JogaJuniorMain li {
  text-align: justify;
}
