.RegulaminMain{
    text-align: center;
}

.RegulaminMain ol {
    text-align: left;
}

.RegulaminMain ol li {
    padding: 4px 0;
}


.RegulaminMain h4 {
    text-transform: uppercase;
    color: red;
}