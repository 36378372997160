.PsiePrzedszkoleMain h3{
    text-align: center;
}

.PsiePrzedszkoleMain h4{
    text-align: center;
}

.PsiePrzedszkoleMain p{
    text-align: justify;
}

.PsiePrzedszkoleMain li{
    text-align: justify;
}