.navigationBarMainDiv{
    background: white;
    border-bottom: 1px solid #00000017;
}

.isActive{
    color: black !important;
    font-weight: 700 !important;
    text-shadow: 1px 1px 2px #fef100;

}

.isInactive{
    color: #5f5f5f !important;
}
