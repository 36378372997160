/* .MuiTabs-flexContainer{
    justify-content: center;
    align-items: center;
} */
/* 


.PrivateTabIndicator-colorPrimary-3{
    background: rgb(48, 50, 51) !important;
} */


/* .MuiTab-textColorPrimary.Mui-selected{
    background-color: #FEF100;
    color: rgba(0, 0, 0, 0.54) !important;
} */

/* .PrivateTabIndicator-colorPrimary-3{
    background:rgba(0, 0, 0, 0.54) !important;
} */

/* .MuiTabs-root button:active{
    outline:red;
} */

.MuiTab-textColorPrimary.Mui-selected{
    background-color: #FEF100;

}

.MuiAppBar-colorDefault{
    background-color: white !important;
}

.MuiPaper-elevation4 {
box-shadow: none !important;
}
/* .MuiTabs-flexContainer{
    align-items: center !important;
    justify-content: center !important;
} */