/* 
.nav-link.active{
    color: red !important;

} */

#basic-nav-dropdown{
    color: #5f5f5f !important;
}

#is-active{
    color: black !important;
    font-weight: 700 !important;
    text-shadow: 1px 1px 2px #fef100;
}