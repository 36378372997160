.bannerDiv{
    width: 100%;
    height: 25vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fef100;
    /* clip-path: polygon(0 0, 100% 0%, 100% 87%, 0 100%); */
}

.bannerDiv img{
    height: 70%;
}