.rallyOMain h3{
    text-align: center;
}

.rallyOMain h4 {
    text-align: center;
}

.rallyOMain a{
    text-align: center;
}

.rallyOMain p{
    text-align: justify;
}

.rallyOMain .anchorDiv{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

