.footerDiv{
    background-image: linear-gradient(to right bottom, rgba(83, 83, 83, 0.1), rgba(0, 0, 0, 0.8)), url('..//..//assets//images//baner2.webp');
    background-size: cover;
    background-position: top;
    height:  350px;
    width: 100%;
    /* clip-path: polygon(0 13%, 100% 0, 100% 100%, 0% 100%); */
    /* position: absolute;
    bottom: 0px; */
}
