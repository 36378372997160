.OMnieMain h3{
    text-align: center;
}

.OMnieMain h4 {
    text-align: center;
}


.OMnieMain p{
    text-align: justify;
}

.OMnieMain .anchorDiv{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}