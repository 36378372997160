.JogaDogaMain h3 {
  text-align: center;
}

.PsiePrzeJogaDogaMaindszkoleMain h4 {
  text-align: center;
}

.JogaDogaMain p {
  text-align: justify;
}

.JogaDogaMain li {
  text-align: justify;
}
