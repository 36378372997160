ul {
    list-style:none;
    padding-left: 1em;

  li {
    &:before {
      content: '';
      display: inline-block;
      height: 1em;
      width: 1em;
      background-image: url("..//images//paw.svg"); 
      background-size: contain;
      background-repeat: no-repeat;
      margin-right:0.5em;   
    }
  }
}
