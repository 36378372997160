.bannerDiv{
    width: 100%;
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fef100;
    clip-path: polygon(0 0, 100% 0%, 100% 87%, 0 100%);
}

.bannerDiv img{
    height: 70%;
}

.mainDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    
}

.contactDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;
}

.InfoDivMain{
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
}

.InfoDiv{
    margin: 5px;
    padding: 5px;
    min-width: 300px;
    width: 32%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
}

/* .landingDiv{
    background: rgb(221,238,255);
background: linear-gradient(180deg, rgba(255, 255, 255, 0.5665616588432247) 0%, rgba(194, 183, 179, 0.73) 100%)

} */


.tempDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top:10px;
    margin-bottom:10px;
    padding: 10px;
    text-align: center;
}
.contactDiv img{
    max-height: 250px;
    margin: 15px;
    /* clip-path: polygon(0 13%, 100% 0%, 100% 87%, 0% 100%); */

}

.footerDiv{
    background-image: linear-gradient(to right bottom, rgba(83, 83, 83, 0.1), rgba(0, 0, 0, 0.8)), url('..//..//assets//images//baner2.webp');
    background-size: cover;
    background-position: top;
    height:  300px;
    width: 100%;
    clip-path: polygon(0 13%, 100% 0, 100% 100%, 0% 100%);
    /* position: absolute;
    bottom: 0px; */
}
