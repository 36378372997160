@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');

.mainPageHeading{
    text-align: center;
    font-family: 'Caveat', cursive;

}

.mainPageHeading h1 {
    text-shadow: 1px 1px 1px #000;
}

.iconTextDiv{
    display: flex;
    color: #343a40;
}

.iconTextDiv span{
    margin-left: 5px;
}

.topDiv{
    width: 100%;
    /* background: rgb(70, 69, 72); */
    /* background: linear-gradient(to right bottom, rgba(86, 86, 88, 0.77), rgb(39, 36, 37)); */
    padding: 10px;
    color: white;
    border-top: 2px solid #fdfcff;
    background-image: linear-gradient(to right bottom, rgba(76, 76, 76, 0.83), rgba(23, 23, 23, 0.93)), url('..//..//..//assets//images//paws2.webp');
    object-fit: cover;
    /* background-blend-mode: saturation; */


    
}

.contactWrapper{
    width: 100%;
    background: #feffc400;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-weight: 400;
}

.MainPageMainDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: white;
    letter-spacing: 1px;
}





.MainPageImage{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.MainPageImage img{
  width: 200px;
  margin: 10px;
}

.MainPageImage{
    font-weight: 600;
    letter-spacing: 1px;
}

.MainPageImage ul{
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    color: white;
    list-style-type: none !important;
}

.pillsDiv span{
    padding: 5px;
    margin: 2px;
}
.pillsDiv{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    
}

.MainPageImage ul li{
padding: 5px;
}


.mainDivContact{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;

    padding: 10px;
}

.MainPageMainDiv h2{
    font-family: 'Caveat', cursive;
    border: 2px solid #fef100;
    background: #fef100;
    color: rgb(34, 34, 34);
    border-radius: 50px;
    padding: 1px 20px;
    width: 350px;
    text-align: center;
    margin-bottom: 15px;
}



.mainPageDirectionsDiv{
    padding: 10px;
}

.mainPagePayments {
    text-align: center;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.mainPageDirectionsDiv
{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.mainPageDirectionsDiv iframe{
    border-radius: 10px;
    width: 100% !important;
}